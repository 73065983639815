<div class="container mt-5 pt-5">
    <h1 class="text-center text-whithe">Socio</h1>
    <p class="text-center text-justify-laga fs-5">SAÚL GARCÍA MORALES</p>
    <p class="text-wrap text-justify-laga">El Licenciado Saúl García se enfoca en la práctica de litigios y controversias fiscales. Cuenta con amplia experiencia en consultoría y planeación fiscal. Ha asesorado empresas nacionales y transnacionales del ramo tecnológico y farmacéutico.</p>
    <p class="text-wrap text-justify-laga">Ha participado en importantes litigios fiscales de grupos corporativos, que han sido sujetos de auditorías fiscales respecto de operaciones financieras, precios de transferencias, e inexistencias de operaciones, representando para sus clientes resoluciones
        favorables.
    </p>
    <p class="text-wrap text-justify-laga">Asimismo, cuenta con experiencia en litigios y consultorías sobre procedimientos de verificación respecto de prevención de lavado de dinero.</p>
    <p class="text-wrap text-justify-laga">Obtuvo el grado de licenciado en derecho por la Universidad Nacional Autónoma de México, a través de la tesis profesional “Inconstitucionalidad del artículo 146, párrafo cuarto de la Ley del Impuesto sobre la Renta”. Ha cursado diversos diplomados
        en materia fiscal, entre los que se mencionan: Diplomado en Impuestos Federales (ITAM); Diplomado en Derecho Fiscal (Academia Mexicana de Derecho Fiscal); Diplomado en Derecho Procesal Fiscal (UNAM); Diplomado de Derecho Corporativo (UNAM) y Diplomado
        de Prevención de Lavado de Dinero por el Colegio de Contadores Público de México. Es miembro de la Barra Mexicana, Colegio de Abogados.</p>


</div>