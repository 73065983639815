<div class="container mt-5 pt-5">
    <h1 class="text-center fs5">Contacto</h1>
    <hr class="text-light">
    <div class="row">
        <div class="col-md-6 col-sm-12">
            <p><i class="bi bi-geo-alt-fill"></i> Dirección:</p>
            <p>Hegel 228, Polanco, Miguel Hidalgo, 11560, Ciudad de México.</p>
            <p><i class="bi bi-telephone-forward-fill"></i> Teléfono:</p>
            <p>+52 (52) 55 90 12 61 20</p>
            <p><i class="bi bi-envelope-fill"></i> Email:</p>
            <p><a class="link-light" href="mailto:contacto@lagabogados.com">contacto@lagabogados.com</a></p>
        </div>
        <div class="col-md-6 col-sm-12">

            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.436614449405!2d-99.20455229999999!3d19.4367335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d2021b90c3e33f%3A0xb58d74d68d83729a!2sGarc%C3%ADa%20Morales%20Abogados%20S.C.!5e0!3m2!1ses-419!2smx!4v1657319353144!5m2!1ses-419!2smx"
                width="600" height="450" style="border:2;" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>

</div>