<div class="container p-3 mt-5 mb-5">
    <h1 class="text-center">DERECHO FISCAL</h1>

    <ul class="custom-bullet">
        <li class="">Diseño e implementación de estrategias de defensa ante autoridades fiscales (recursos administrativos) y ante tribunales administrativos federales y locales (juicio contencioso administrativo).
        </li>
        <li>Juicio de Amparo en contra de leyes y actos de autoridades federales y locales.</li>
        <li>Atención de procedimientos de verificación practicados por autoridades en el ejercicio de sus facultades de fiscalización, tales como visitas domiciliarias, revisión de gabinete y revisión electrónica.</li>
        <li>Acuerdo conclusivos y quejas ante la Procuraduría de la Defensa del Contribuyente (PRODECON).</li>
        <li>Atención de trámites de devolución de saldos a favor y pagos indebidos o realizados en exceso.</li>
        <li>Atención a la cancelación del certificado de sellos digitales por las autoridades fiscales.</li>
        <li>Procedimientos de verificación y defensa legal en materia de Comercio Exterior.</li>
        <li>Consultas y dudas en materia fiscal en operaciones en específico.</li>
    </ul>

    <h1 class="text-center">DERECHO CORPORATIVO</h1>
    <ul class="custom-bullet">
        <li>Redacción de estatutos sociales, registro y constitución de personas morales, atendiendo a la forma jurídica que mejor se ajuste a las actividades de la empresa.</li>

        <li>Due diligence y auditorías legales para fusiones y adquisiciones, asesoría y negociación en materia de convenios entre accionistas y gobierno corporativo.</li>

        <li>Servicios de consultoría en materia de inversión extranjera, inversión neutra, e inversión en sectores restringidos a la inversión extranjera, así como preparación y administración de informes periódicos en dicha materia.</li>

        <li>Redacción, análisis y preparación de contratos comerciales y preparación de estrategias legales para la consecución de proyectos comerciales.</li>

        <li>Preparación y mantenimiento de libros societarios, redacción de actas de asambleas, redacción, registro y control de poderes, aumentos y disminuciones de capital, transferencias y compraventas de acciones y partes sociales.
        </li>

    </ul>


</div>