<header>

    <nav class="navbar navbar-expand-md fixed-top navbar-dark bg-dark">

        <div class="container-fluid">
            <div class="d-block">
                <a class="navbar-brand" href="#">
                    <img src="./assets/logo.png" class="" alt="" width="auto" height="40">
                </a>
            </div>


            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="isShown = !isShown">
                <span class="navbar-toggler-icon"></span>
            </button>


            <div class="collapse navbar-collapse" [ngClass]="{ 'show': isShown }" id="navbarCollapse">
                <div class="container d-flex justify-content-center">
                    <ul class="navbar-nav  mb-2 mb-md-0">

                        <li class="nav-item more-sapace">
                            <a class="nav-link" aria-current="page" routerLink="/" (click)="isShown = false" routerLinkActive="active">INICIO</a>
                        </li>
                        <li class="nav-item more-sapace">
                            <a routerLink="/nosotros" routerLinkActive="active" (click)="isShown = false" class="nav-link">NOSOTROS</a>
                        </li>
                        <li class="nav-item more-sapace">
                            <a routerLink="/practicas" routerLinkActive="active" (click)="isShown = false" class="nav-link">PRÁCTICAS</a>
                        </li>

                        <li class="nav-item more-sapace">
                            <a routerLink="/contacto" routerLinkActive="active" (click)="isShown = false" class="nav-link">CONTACTO</a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/lagabogados/" target="_blank" class="nav-link">
                                <i class="bi bi-linkedin"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://wa.me/5215581713929" target="_blank" class="nav-link">
                                <i class="bi bi-whatsapp"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>


        </div>
    </nav>

</header>