<div class="overflow-hidden p-3 p-md-5 m-md-0 text-center bg-lineal-gradient">
    <div class="col-md-6 p-lg-6 mx-auto my-5">
        <h1 class="display-4 fw-normal">García Abogados S.C</h1>


    </div>
</div>
<div class="container p-5 mb-5">
    <h1 class="text-center text-whithe">LA FIRMA</h1>
    <div class="row">
        <div class="col-md-6">
            <p class="text-justify-laga">Firma legal boutique, especializada en Derecho Fiscal y Derecho Corporativo,
                que conjunta más de 12 años de talento y experiencia, con el dinamismo de la nueva forma de hacer
                negocios.
            </p>
        </div>
        <div class="col-md-6 ">
            <p class="text-justify-laga">Tenemos la convicción que un asesor legal es fundamental para la operación y
                crecimiento de una empresa. Nuestros servicios legales consisten en brindar soluciones de vanguardia y
                alta especialización, en los asuntos más complejos e innovadores,
                y para los clientes más sofisticados y exigentes.
            </p>
        </div>

    </div>

</div>